import axios from 'axios';
import qs from 'qs';

const myPostAxios = (url, post) => {
    return new Promise(function (resolve, reject) {
        let request= {
            method: 'POST',
            url: url,
            headers: {
                'Content-type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            data: qs.stringify(post)
        }
        axios(request).then((response) => {
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

export default myPostAxios;