import autocomplete from 'autocompleter'
import myPostAxios from "../services/myPostAxios"
// import UIkit from 'uikit/dist/js/uikit-core';
import UIkit from 'uikit';

import flatpickr from 'flatpickr';
import { French } from 'flatpickr/dist/l10n/fr.js';
import { english } from 'flatpickr/dist/l10n/default.js';

/**
 ** Utils
 */

 let autocompleteTimeout = null;

const Utils = () => {
    const textInputs = document.querySelectorAll('.kg-input-close')
    if (textInputs) {
        [...textInputs].forEach((textInput) => {
            textInput.onkeyup = function () {
                
            };
        })
    }

    const event_autocomplete = document.querySelectorAll('.kg-event-autocomplete')
    if (event_autocomplete) {
        [...event_autocomplete].forEach((inputText) => {
            autocomplete({
                input: inputText,
                
                minLength: 0,
                debounceWaitMs: 60,
                preventSubmit: true,
                disableAutoSelect: false,
                emptyMsg: 'Aucune correspondance trouvée',
                fetch: function (text, update, trigger) {
                    let sugg = [{ 'label': 'Ma position', value: 'geolocation' }];
                    
                    if (inputText.id == 'arret_start' && localStorage.getItem('lastFromSearches') ) {
                        let storedFromSearch = JSON.parse(localStorage.getItem('lastFromSearches'));
                        storedFromSearch = storedFromSearch.reverse();
                        for (const fromSearch of Object.values(storedFromSearch)) {
                            if((fromSearch.label != 'Ma position') && (fromSearch.label != '')){
                                sugg.push({ label: fromSearch.label, value: fromSearch.value, group: "Dernières recherches" })
                            }
                        }
                    }
                    if ($('.horaire_tps_reel').data('islogged') == 1){
                        let data = localStorage.getItem('favTpsReel');
                        if (data && data.length > 0) {
                            data = JSON.parse(data);

                            for (const [key, value] of Object.entries(data)) {
                                sugg.push({ label: value, value: value, group: "Mes Favoris" });
                            };
                        }
                    }
                    
                    if (text && text.length > 2) {
                        //console.log(inputText.form.dataset.url);
                        clearTimeout(autocompleteTimeout);
                        autocompleteTimeout = setTimeout(function() {

                            getDataAutocomplete(inputText, text, update, "autocomplete_arret", sugg)
                        }, 1500);
                    } else {
                        update(sugg);
                    }
                },
                click: function (e) { e.fetch(); },
                onSelect: function (item) {
                    
                    inputText.value = item.label;
                    //console.log(inputText.dataset.target);
                    const target = document.getElementById(inputText.dataset.target);
                    //console.log(target);
                    
                    
                    if (target) {
                        if (item.value == 'geolocation') {
                            navigator.geolocation.getCurrentPosition(
                                function (pos) {
                                    const coords = pos.coords;
                                    const departLat = document.getElementById('start_lat');
                                    const departLong = document.getElementById('start_long');
                                    
                                    target.value = `${coords.longitude};${coords.latitude}`;
                                    target.nextElementSibling.value = item.label;
                                    departLat.value = coords.latitude;
                                    departLong.value = coords.longitude;
                                },
                                function (error) {
                                    UIkit.notification('Votre position n\'a pas été trouvée', { status: 'error', pos: 'bottom-right' })
                                    console.warn(error);
                                    inputText.value = ''
                                }
                            )
                        } else {
                            target.value = item.value;
                            target.nextElementSibling.value = item.label;
                        }
                    } else {
                        inputText.value = ''
                    }
                    inputText.blur();
                },
                render: function (item) {
                                       
                    const itemElement = document.createElement("div");
                    itemElement.textContent = item.label;
                    return itemElement;
                }
            });
        })
    }
    const getDataAutocomplete = (input, text, update, endpoint, sugg) => {
     

        let post = {
            requete: endpoint,
            requete_val: {
                request: text
            }
        };
        
        //myPostAxios('?type=476', post).then((data) => {
        myPostAxios(input.form.dataset.url, post).then((data) => {
            if (data) {
                let sugg = []
                for (let i = 0; i < data.length; i++) {
                    sugg.push({ label: data[i], value: data[i] })
                }
                update(sugg)
            } else {
                input.value = ''
                //input.previousElementSibling.setAttribute("hidden", "")
                UIkit.notification("Aucune correspondance trouvée", { status: 'error', pos: 'bottom-right' })
                update([]);
            }
        }).catch((err) => {
            console.log(err.responseText)
            input.value = ''
            //input.previousElementSibling.setAttribute("hidden", "")
            UIkit.notification("Aucune correspondance trouvée", { status: 'error', pos: 'bottom-right' })
            update([]);
        });
    }

    async function checkGeolocationPermission() {
        return new Promise((resolve, reject) => {
          if (navigator.permissions) {
            navigator.permissions.query({ name: 'geolocation' }).then(function(permissionStatus) {
              if (permissionStatus.state === 'granted') {
                resolve(true); 
              } else {
                resolve(false); 
              }
            }).catch(reject);
          } else {
            resolve(true);
          }
        });
    }
}

//TODO: refacto
const GeolocationFrom = () => {
    const geolocateEvents = document.querySelector('.event-geolocation-start')

    //console.info(geolocateEvents);

    if (!geolocateEvents) {
        return false
    }

    geolocateEvents.onclick = function () {
        //console.info('click fired');
        navigator.geolocation.getCurrentPosition(
            function (pos) {
                const coords = pos.coords;
                 console.info('position', coords);

                const depart = document.getElementById('arret_start');
                depart.value = 'Ma position';
                const departId = document.getElementById('start_id');
                departId.value = `${coords.longitude};${coords.latitude}`;
                departId.nextElementSibling.value = 'Lieu actuel';
            },
            function (error) {
                UIkit.notification('Votre position n\'a pas été trouvée', { status: 'error', pos: 'bottom-right' })
                console.warn(error);
            }
        );
    }
}



const StoreLastSearch = () => {
    const submitButton = document.querySelector('#form_tpsreel_submit');
    if (!submitButton) {
        return false;
    }

    submitButton.onclick = function () {
        const fromInput = document.getElementById('arret_start');
        const fromId = document.getElementById('start_id');
        let storedFromSearch = new Array();
        const lastFromSearch = { 'label': fromInput.value, 'value': fromId.value };
        if (localStorage.getItem('lastFromSearches')) {
            storedFromSearch = JSON.parse(localStorage.getItem('lastFromSearches'));
            for (let index in storedFromSearch)
                if (storedFromSearch[index].value === lastFromSearch.value) storedFromSearch.splice(index, 1)
            if (storedFromSearch.length == 5) {
                storedFromSearch.shift();
            }
        }
        storedFromSearch.push(lastFromSearch);
        localStorage.setItem('lastFromSearches', JSON.stringify(storedFromSearch));
    }

    // return false;
}


function requeteFavorisTpsReelFavori() {
    var url = $('#horairestpsreel-form').data("url"),
        $loader = $('#horairestpsreel_loader');

    return new Promise(function(resolve, reject) {
        $.ajax({
            url: url,
            type: 'post',
            data: {
                requete: 'horaires_arrets_islogged_name'
            },
            beforeSend: function () {
                $loader.show();
            },
            success: function (data) {
                $loader.hide();
                if (data) {
                    resolve(data);
                } else {
                    reject('Erreur de récupération des données');
                }
            },
            error: function () {
                $loader.hide();
                reject('Erreur de récupération des données');
            }
        });
    });
}

function initialiserPage() {
    if ($('.horaire_tps_reel').data('islogged') == 1){
        requeteFavorisTpsReelFavori()
            .then(function(data) {

                // Vérifier si les données ne sont pas vides
                if (data && data.length > 0) {
                    localStorage.setItem('favTpsReel',data);
                }
            })
            .catch(function(error) {
                console.error(error);
            });
    }
}

// Appeler initialiserPage au chargement de la page
window.addEventListener('load', initialiserPage);


/**
 ** Init All Widgets
 */
const KGWidgetTpsReel = () => {
    Utils()
    GeolocationFrom()
    StoreLastSearch()
}

document.addEventListener('DOMContentLoaded', KGWidgetTpsReel);
